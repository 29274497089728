<template>
    <div class="home-wrap">
        <div class="main-con-wrap">
            <div class="layout">
                <div class="main-con">
                    <div class="lcon">
                        <div class="logo"></div>
                        <div class="title">
                            Share photos to friends' home screens
                        </div>
                        <div class="desc">
                            TickBoom is a social app to share real-time photos and compete to be displayed on one of the three slots on the screen of each other's mobile phones for you and your 8 closest people. Start experiencing the joy of "Tick, Boom!" with your close friends, lover and family now.
                        </div>
                        <div class="qrcode">
                            <img :src="qrcode"
                                 alt=""
                                 class="pic">
                            <div class="txt">Scan the code to download the app</div>
                        </div>
                        <div class="btn-wrap">
                            <div class="btn apple"
                                 @click="downloadIos"></div>
                            <div class="btn android"
                                 @click="downloadAndroid"></div>
                        </div>
                    </div>
                    <div class="rcon">
                        <img :src="pic1"
                             alt=""
                             class="pic">
                    </div>
                </div>
            </div>
        </div>

        <div class="footer">
            <div class="txt-con">
               <span>Copyright@2020-2022 Drfun</span>
            </div>
        </div>

        <div class="download-tip"
             @click="showTip = false"
             v-if="showTip">
            <div class="txt"></div>
        </div>
    </div>
</template>

<script>
import { isWeixin } from '@/utils/index'
export default {
    name: 'Home',
    metaInfo: {
        title: 'TickBoom',
        meta: [
            {
                name: 'keyWords',
                content: 'TickBoom',
            },
            {
                name: 'description',
                content:
                    'TickBoom, Share photos to friends\' homescreens',
            },
        ],
    },
    components: {},
    data () {
        return {
            pic1: require('@/assets/home/pic-1.png'),
            qrcode: require('@/assets/home/qrcode.png'),
            showTip: false,
        }
    },
    methods: {
        downloadIos () {
            if (isWeixin()) {
                this.showTip = true
                return
            }
            location.href = 'https://apps.apple.com/app/tickboom/id1606988576'
        },
        downloadAndroid () {
            if (isWeixin()) {
                this.showTip = true
                return
            }
            location.href = '/download/tickboom_last.apk'
        },
    },
}
</script>

<style lang="scss">
.home-wrap {
    display: flex;
    align-items: center;
    flex-direction: column;
    min-height: 100vh;
    width: 100vw;

    .download-tip {
        background: rgba($color: #000000, $alpha: 0.7);
        position: fixed;
        z-index: 99;
        left: 0;
        top: 0;
        width: 100vw;
        height: 100vh;
        .txt {
            position: absolute;
            background-image: url("~@/assets/home/download-tip.png");
            width: 210px;
            height: 107px;
            background-size: 100% 100%;
            right: 20px;
            top: 10px;
        }
    }

    .layout {
        max-width: 1200px;
        margin: 0 auto;
    }

    .main-con-wrap {
        background-image: url("~@/assets/home/bg.png");
        background-size: cover;
        background-repeat: no-repeat;
        flex: 1;
        width: 100vw;
        min-height: 760px;
    }

    .main-con {
        padding-top: 85px;
        min-height: 600px;
        display: flex;
        .lcon {
            .logo {
                background-image: url("~@/assets/home/logo.png");
                width: 372px;
                height: 86px;
                background-size: 100% 100%;
            }
            .title {
                font-size: 34px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #ffecd8;
                line-height: 46px;
                margin-top: 58px;
            }
            .desc {
                font-size: 16px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #ffecd8;
                line-height: 29px;
                margin-top: 9px;
                width: 568px;
                opacity: 0.7;
            }
            .qrcode {
                width: 154px;
                height: 198px;
                background: #ffffff;
                box-shadow: 0px 0px 9px 0px rgba(96, 96, 97, 0.13);
                border-radius: 15px;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                margin-top: 20px;
                .pic {
                    width: 139px;
                    height: 139px;
                }
                .txt {
                    margin-top: 4px;
                    font-size: 14px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #9297b0;
                    line-height: 15px;
                    text-align: center;
                }
            }

            .btn-wrap {
                display: flex;
                align-items: center;
                margin-top: 40px;
                .btn {
                    width: 172px;
                    height: 53px;
                    background-size: 100% 100%;
                    cursor: pointer;

                    &.apple {
                        background-image: url("~@/assets/home/download-btn-apple.png");
                    }
                    &.android {
                        background-image: url("~@/assets/home/download-btn-android.png");
                    }
                    &:first-child {
                        margin-right: 25px;
                    }
                }
            }
        }
        .rcon {
            margin-left: 99px;
            width: 529px;
            .pic {
                width: 100%;
            }
        }
    }

    .footer {
        background: #1d1d20;
        padding: 17px 0;
        width: 100vw;
        .txt-con {
            font-size: 14px;
            font-weight: 400;
            color: #ffffff;
            line-height: 22px;
            text-align: center;
            opacity: 0.5;
            .sp {
                display: none;
            }
        }
    }
}
</style>

<style lang="scss">
@media only screen and (max-width: 600px) {
    .home-wrap {
        .layout {
            padding: 0 10px;
        }

        .main-con {
            display: block;
            padding-top: 40px;
            .lcon {
                .logo {
                    width: 260px;
                    height: 60px;
                }
                .title {
                    font-size: 20px;
                    line-height: 26px;
                    margin-top: 30px;
                }
                .desc {
                    font-size: 16px;
                    width: auto;
                    line-height: 22px;
                }
                .qrcode {
                    display: none;
                }
                .btn-wrap {
                    margin-top: 36px;
                    justify-content: space-between;
                }
            }
            .rcon {
                margin-left: 0;
                width: auto;
                margin-top: 36px;
                .pic {
                    width: 100%;
                    height: auto;
                }
            }
        }

        .footer {
            .txt-con {
                font-size: 12px;
                .sp {
                    display: block;
                }
            }
        }
    }
}
</style>
